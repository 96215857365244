import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface HowToPlayModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HowToPlayModal: React.FC<HowToPlayModalProps> = ({ isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            className="bg-background p-6 rounded-lg max-w-md"
            onClick={(e: { stopPropagation: () => any; }) => e.stopPropagation()}
          >
            <h2 className="text-2xl font-heading font-bold mb-4">How to Play Mathdle</h2>
            <ul className="list-disc pl-5 space-y-2">
              <li>Guess the hidden mathematical function in 6 tries or less.</li>
              <li>Each guess must be a valid mathematical function using the provided operators.</li>
              <li>After each guess, the color of the tiles will change to show how close your guess was.</li>
              <li>Green means the character is correct and in the right position.</li>
              <li>Yellow means the character is in the function but in the wrong position.</li>
              <li>Gray means the character is not in the function at all.</li>
            </ul>
            <button
              className="mt-6 bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-light transition-colors"
              onClick={onClose}
            >
              Got it!
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HowToPlayModal;
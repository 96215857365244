// components/GuessBoard.tsx
import React from 'react';
import { motion } from 'framer-motion';

interface GuessBoardProps {
    inputHints: string[];
    outputHints: string[];
    guesses: string[][];
    guessResults: string[][];
    currentGuess: number;
}

const GuessBoard: React.FC<GuessBoardProps> = ({ inputHints, outputHints, guesses, guessResults, currentGuess }) => {
    const getCellColor = (row: number, col: number) => {
        if (row >= currentGuess) return 'bg-white';
        switch (guessResults[row][col]) {
            case 'correct':
                return 'bg-green-500 text-white';
            case 'present':
                return 'bg-yellow-500 text-white';
            case 'absent':
                return 'bg-gray-500 text-white';
            default:
                return 'bg-white';
        }
    };

    return (
        <div className="flex items-center space-x-2 bg-white p-4 rounded-lg shadow-md" aria-label="Guess Board">
            <div className="flex flex-col items-center">
                <div className="mb-2 font-bold">x =</div>
                <div className="grid grid-rows-6 gap-2">
                    {inputHints.slice(0, currentGuess + 1).map((hint, index) => (
                        <motion.div 
                            key={index} 
                            className="w-8 h-10 bg-blue-200 flex items-center justify-center font-bold"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                            {hint}
                        </motion.div>
                    ))}
                    {[...Array(6 - (currentGuess + 1))].map((_, index) => (
                        <div key={index + currentGuess + 1} className="w-8 h-10 bg-gray-100 flex items-center justify-center">
                            ?
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-col items-center">
                <div className="mb-2 font-bold">f(x) =</div>
                <div className="grid grid-rows-6 gap-2">
                    {guesses.map((guess, rowIndex) => (
                        <div key={rowIndex} className="grid grid-cols-6 gap-1" aria-label={`Guess ${rowIndex + 1}`}>
                            {guess.map((cell, cellIndex) => (
                                <motion.div
                                    key={cellIndex}
                                    initial={{ scale: 1 }}
                                    animate={{ scale: cell !== '' ? [1, 1.1, 1] : 1 }}
                                    transition={{ duration: 0.2 }}
                                    className={`w-10 h-10 border-2 border-gray-300 flex items-center justify-center text-lg font-bold transition-all
                ${cell !== '' ? 'border-gray-600' : ''} ${getCellColor(rowIndex, cellIndex)}`}
                                    aria-label={`Input ${cellIndex + 1}`}
                                >
                                    {cell}
                                </motion.div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-col items-center">
                <div className="mb-2 font-bold">f(x) =</div>
                <div className="grid grid-rows-6 gap-2">
                    {outputHints.slice(0, currentGuess + 1).map((hint, index) => (
                        <motion.div 
                            key={index} 
                            className="w-8 h-10 bg-green-200 flex items-center justify-center font-bold"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                            {hint}
                        </motion.div>
                    ))}
                    {[...Array(6 - (currentGuess + 1))].map((_, index) => (
                        <div key={index + currentGuess + 1} className="w-8 h-10 bg-gray-100 flex items-center justify-center">
                            ?
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GuessBoard;
import React from 'react';
import { motion } from 'framer-motion';

interface KeyboardProps {
  onKeyPress: (key: string) => void;
  onDelete: () => void;
  onSubmit: () => void;
  blockedKeys: Set<string>;
}

const Keyboard: React.FC<KeyboardProps> = ({ onKeyPress, onDelete, onSubmit, blockedKeys }) => {
  const keys: string[][] = [
    ['7', '8', '9', '+', '-', '*'],
    ['4', '5', '6', '/', '^', 'x'],
    ['1', '2', '3', '0', 'Delete', 'Submit']
  ];

  return (
    <div className="bg-secondary p-4 rounded-lg shadow-md" role="group" aria-label="Virtual Keyboard">
      {keys.map((row, rowIndex) => (
        <div key={rowIndex} className="grid grid-cols-6 gap-2 mb-2">
          {row.map((key) => (
            <motion.button
              key={key}
              onClick={() => key === 'Delete' ? onDelete() : key === 'Submit' ? onSubmit() : onKeyPress(key)}
              whileTap={{ scale: 0.95 }}
              className={`
                py-3 text-text font-bold rounded-lg text-lg transition-all
                ${key === 'Delete' ? 'bg-accent text-white' : 
                  key === 'Submit' ? 'bg-primary text-white' : 
                  blockedKeys.has(key) ? 'bg-secondary-dark cursor-not-allowed' : 
                  'bg-primary-light hover:bg-primary active:bg-primary-dark'}
              `}
              aria-label={`Key ${key}`}
              disabled={blockedKeys.has(key) && key !== 'Delete' && key !== 'Submit'}
            >
              {key}
            </motion.button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Keyboard;
// hooks/useKeyboardInput.ts
import { useEffect } from 'react';

export const useKeyboardInput = (
  handleInput: (key: string) => void,
  handleDelete: () => void,
  handleSubmit: () => void
) => {
  useEffect(() => {
    const handleKeyboardInput = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      
      if (/^[0-9x+\-*/^]$/.test(key)) {
        handleInput(key);
      } else if (key === 'backspace' || key === 'delete') {
        handleDelete();
      } else if (key === 'enter') {
        handleSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyboardInput);
    return () => {
      window.removeEventListener('keydown', handleKeyboardInput);
    };
  }, [handleInput, handleDelete, handleSubmit]);
};